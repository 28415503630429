<template>
	<div class="flex-container--col--center">
		<h1 class="page-title">페이지 준비중입니다.</h1>
	</div>
</template>

<script>
export default {
	components: {},
};
</script>

<style></style>
